import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from '../utils/basicHelpers';
import { isEmpty } from 'lodash';

const PrivateRoute = () => {

    const auth = useSelector(x => x.auth.value);
    const loc = useLocation();

    if(["/users", "/users/add", "/users/update", "/users/permission"]?.includes(loc.pathname) && !auth?.roles?.includes('ADMIN')){
        return <>Un-Authorized access</>
    }
    if (isEmpty(auth)) {
        return <Navigate to="/" state={{ from: history.location }} />
    }

    return <Outlet />;
}

export default PrivateRoute