export const cricketData = [
  {
    title: "Tournament",
    path: "/cricket/tournament",
  },
  // {
  // 	title: "Tournament Detail",
  // 	path: "/cricket/tournament-detail",
  // },
  {
    title: "Contest",
    path: "/cricket/contest",
  },
  {
    title: "Competitor",
    path: "/cricket/competitor",
  },
  {
    title: "Player",
    path: "/cricket/player",
  },
  {
    title: "Matches",
    path: "/cricket/match",
  },
  // {
  // 	title: "Commentary Options",
  // 	path: "/cricket/commentary-options",
  // },
  {
    title: "Officials",
    path: "/cricket/official",
  },
  {
    title: "Venues",
    path: "/cricket/venue",
  },
  {
    title: "Coach",
    path: "/cricket/coach",
    // subNav: [
    //   {
    //     title: "Cricket Coach",
    //     path: "/cricket/cricket-coach",

    //   }
    // ],
  },
  {
    title: "Ranking",
    path: "/cricket/ranking",
  },
];

export const systemData = [
  {
    title: "Users",
    path: "/users",
  },
  {
    title: "Country",
    path: "/country",
  },
  {
    title: "Seasons",
    path: "/seasons",
  },
  {
    title: "Promotions",
    path: "/promotions",
  },
  {
    title: "Group Promotions",
    path: "/group-promotions",
  },
  {
    title: "Tv Channel",
    path: "/tvchannel",
  },
  {
    title: "Language",
    path: "/language",
  },
];

export const footbalData = [
  {
    title: "Tournament",
    path: "/football/tournament",
  },
  {
    title: "Contest",
    path: "/football/contest",
  },
  {
    title: "Competitor",
    path: "/football/competitor",
  },
  {
    title: "Player",
    path: "/football/player",
  },
  {
    title: "Matches",
    path: "/football/match",
  },
  {
    title: "Officials",
    path: "/football/official",
  },
  {
    title: "Venues",
    path: "/football/venue",
  },
  {
    title: "Coach",
    path: "/football/coach",
  },
  {
    title: "Match Mapping",
    path: "/football/match-mapping",
  },
  {
    title: "Provider Search",
    path: "/football/provider-search",
  },
  {
    title: "IPublisher Notification Mapping",
    path: "/football/ipublisher-notification-mapping",
  },
 
];

export const golfData = [
  {
    title: "Tournament",
    path: "/golf/tournament",
  },
  {
    title: "Players",
    path: "/golf/player",
  },
  {
    title: "Player Group",
    path: "/golf/player-group",
  },
  {
		title: "Venue",
		path: "/golf/venue",
	},
  {
    title: "Matches",
    path: "/golf/match",
  },
  // {
	// 	title: "Venue Hole",
	// 	path: "/golf/venue-hole",
	// },
]

export const tennisData = [
  {
    title: "Tournament",
    path: "/tennis/tournament",
  },
  {
    title: "Contest",
    path: "/tennis/contest",
  },
  {
    title: "Double",
    path: "/tennis/double",
  },
  {
    title: "Team",
    path: "/tennis/team",
  },
  {
    title: "Players",
    path: "/tennis/player",
  },
  {
    title: "Matches",
    path: "/tennis/match",
  },
  {
    title: "Team Matches",
    path: "/tennis/team-matches",
  },
  {
    title: "Officials",
    path: "/tennis/official",
  },
  {
    title: "Venues",
    path: "/tennis/venue",
  },
  {
    title: "Coach",
    path: "/tennis/coach",
  },
  {
    title: "Ranking",
    path: "/tennis/ranking",
  },
  {
    title: "Match Mapping",
    path: "/tennis/match-mapping",
  },
  {
    title: "Provider Search",
    path: "/tennis/provider-search",
  },
];

export const basketballData = [
  {
    title: "Tournament",
    path: "/basketball/tournament",
  },
  {
    title: "Contest",
    path: "/basketball/contest",
  },
  {
    title: "Competitor",
    path: "/basketball/competitor",
  },
  {
    title: "Player",
    path: "/basketball/player",
  },
  {
    title: "Matches",
    path: "/basketball/match",
  },
  {
    title: "Match Mapping",
    path: "/basketball/match-mapping",
  },
  {
    title: "Official",
    path: "/basketball/official",
  },

  {
    title: "Venue",
    path: "/basketball/venue",
  },
  {
    title: "Coach",
    path: "/basketball/coach",
  },
  {
    title: "Provider Search",
    path: "/basketball/provider-search",
  },
];

export const handballData = [
  {
    title: "Tournament",
    path: "/handball/tournament",
  },
  {
    title: "Contest",
    path: "/handball/contest",
  },
  {
    title: "Competitor",
    path: "/handball/competitor",
  },
  // {
  //   title: "Player",
  //   path: "/handball/player",
  // },
  {
    title: "Matches",
    path: "/handball/match",
  },
  {
    title: "Venue",
    path: "/handball/venue",
  },
  {
    title: "Official",
    path: "/handball/official",
  },
  {
    title: "Coach",
    path: "/handball/coach",
  },
];

export const hockeyData = [
  {
    title: "Tournament",
    path: "/hockey/tournament",
  },
  {
    title: "Contest",
    path: "/hockey/contest",
  },
  {
    title: "Competitor",
    path: "/hockey/competitor",
  },
  {
    title: "Player",
    path: "/hockey/player",
  },
  {
    title: "Matches",
    path: "/hockey/match",
  },
  {
    title: "Official",
    path: "/hockey/official",
  },

  {
    title: "Venue",
    path: "/hockey/venue",
  },
  {
    title: "Coach",
    path: "/hockey/coach",
  },
  {
    title: "Provider Search",
    path: "/hockey/provider-search",
  },
];

export const feildHockeyData = [
  {
    title: "Tournament",
    path: "/field-hockey/tournament",
  },
  {
    title: "Contest",
    path: "/field-hockey/contest",
  },
  {
    title: "Competitor",
    path: "/field-hockey/competitor",
  },
  {
    title: "Matches",
    path: "/field-hockey/match",
  },
  {
    title: "Venue",
    path: "/field-hockey/venue",
  },
];

export const volleyballData = [
  {
    title: "Tournament",
    path: "/volleyball/tournament",
  },
  {
    title: "Contest",
    path: "/volleyball/contest",
  },
  {
    title: "Competitor",
    path: "/volleyball/competitor",
  },
  {
    title: "Matches",
    path: "/volleyball/match",
  },
  {
    title: "Venue",
    path: "/volleyball/venue",
  },
];

export const SnookerData = [
	{
		title: "Tournament",
		path: "/snooker/tournament",
	},
	{
		title: "Contest",
		path: "/snooker/contest",
	},
	{
		title: "Player",
		path: "/snooker/player",
	},
	{
		title: "Double",
		path: "/snooker/double",
	},
	{
		title: "Teams",
		path: "/snooker/team",
	},
	{
		title: "Matches",
		path: "/snooker/match",
	},
	{
		title: "Officials",
		path: "/snooker/official",
	},
	{
		title: "Venues",
		path: "/snooker/venue",
	},
	{
		title: "Rank",
		path: "/snooker/rank",
	}
];

export const americanFootballData = [
  {
    title: "Tournament",
    path: "/american-football/tournament",
  },
  {
    title: "Contest",
    path: "/american-football/contest",
  },
  {
    title: "Competitor",
    path: "/american-football/competitor",
  },
  {
    title: "Player",
    path: "/american-football/player",
  },
  {
    title: "Matches",
    path: "/american-football/match",
  },
  {
    title: "Venues",
    path: "/american-football/venue",
  }
];
export const floorBall = [
	{
		title: "Tournament",
		path: "/floorball/tournament",
	},
	{
		title: "Contest",
		path: "/floorball/contest",
	},
	{
		title: "Competitor",
		path: "/floorball/competitor",
	},
  
	{
		title: "Matches",
		path: "/floorball/match",
	},
	{
		title: "Venue",
		path: "/floorball/venue",
	},
];

export const rugbyUnionData = [
  {
		title: "Tournament",
		path: "/rugby-union/tournament",
	},
  {
    title: "Contest",
    path: "/rugby-union/contest",
  },
  {
		title: "Competitor",
		path: "/rugby-union/competitor",
	},
  {
    title: "Player",
    path: "/rugby-union/player",
  },
  {
    title: "Matches",
    path: "/rugby-union/match",
  },
  {
    title: "Officials",
    path: "/rugby-union/official",
  },
  {
    title: "Venues",
    path: "/rugby-union/venue",
  },

  {
    title: "Coach",
    path: "/rugby-union/coach",
  },
  {
    title: "Provider Search",
    path: "/rugby-union/provider-search",
  },
]
export const rugbyLeagueData = [
  {
		title: "Tournament",
		path: "/rugby-league/tournament",
	},
  {
    title: "Contest",
    path: "/rugby-league/contest",
  },
  {
		title: "Competitor",
		path: "/rugby-league/competitor",
	},
  {
    title: "Player",
    path: "/rugby-league/player",
  },
  {
    title: "Matches",
    path: "/rugby-league/match",
  },
  {
    title: "Officials",
    path: "/rugby-league/official",
  },
  {
    title: "Venues",
    path: "/rugby-league/venue",
  },

  {
    title: "Coach",
    path: "/rugby-league/coach",
  },
  {
    title: "Provider Search",
    path: "/rugby-league/provider-search",
  },
]