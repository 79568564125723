/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.scss";
import img1 from "../../assets/imgs/img1.png";
import img2 from "../../assets/imgs/img2.png";
// import { ReactComponent as CricIcon } from "../../assets/svg/cricketIcon.svg"
// import CricIcon from "../../assets/svg/cricketIcon.svg";
import { useEffect } from "react";

const DropDownComponent = ({ title, list, status }) => {
  const [active, setActive] = useState(status);
  const li = list.map((item, index) => {
    return (
      <div className="dropdownitem">
        <li className="listitem11" key={index}>
          {/* <Link 
                    className='dropdownsub'
                     to={item?.path}>{item?.title}</Link> */}
          <NavLink
            className="dropdownsub"
            to={item?.path}
          >
            {item?.title}
          </NavLink>
        </li>
      </div>
    );
  });

  return (
    <div>
      <div
        className={`navbar11 d-flex justify-content-between ${active && "activeBg11"
          }`}
        onClick={() => setActive(!active)}
      >
        <h6 className="headingdropdown mb-0"> {title}</h6>
        <p className="mb-0">
          {" "}
          {active ? (
            <img className="imgnav1" src={img2} alt="icon" />
          ) : (
            <img className="imgnav1" src={img1} alt="icon" />
          )}
        </p>
      </div>
      <ul className="list my-0">
        <div className="insideDive">{active && li}</div>
      </ul>
    </div>
  );
};

const Dropdown = ({ title, list, status, children, currentMenu, setCurrentMenu, name }) => {

  const [active, setActive] = useState(currentMenu === name ? true : false);

  useEffect(() => {
    setActive(currentMenu === name ? true : false);
  }, [currentMenu]);

  const li = list.map((item, index) => {
    return (
      <li className="listitem11" key={index}>
        {item?.subNav ? (
          <DropDownComponent
            title={item?.title}
            list={item?.subNav}
            status={false}
          />
        ) : (
          <NavLink
            className="dropdownitem"
            to={item?.path}
          >
            {item?.title}
          </NavLink>
        )}
      </li>
    );
  });

  return (
    <div className="sidebar_menu_item">
      <a href={`/${name}/match`}>
      <div
        className={`navbar11 d-flex justify-content-between align-items-center ${active && "activeBg"
          }`}
        onClick={(e) => { setActive(!active) || setCurrentMenu(name) }}
      >
        <h5 className="d-flex align-items-start heading mb-0">
          {" "}
          {children} {title}
        </h5>
          {" "}
          {active ? (
            <img className="imgnav" src={img2} alt="icon" />
          ) : (
            <img className="imgnav" src={img1} alt="icon" />
          )}
      </div>
      </a>
      <ul className="sidebar_menu_inner">
        {active && li}
      </ul>
    </div>
  );
};
export default Dropdown;
