import axios from "axios";
import ToastComp from "components/toast/ToastComp";

// export default axiosServices
// http://192.168.32.36:8080
// https://javaentrypanel.6lgx.com:8080
// https://javaentrypanel.6lgx.com:8080
// http://192.168.32.36:8080 latest local @naeem
// https://sportcccmsbackend.6lgx.com:8080
// REACT_APP_APIURL
// REACT_APP_STAGINGAPIURL

const client = axios.create({
  baseURL: process.env.REACT_APP_STAGINGAPIURL,
  // headers: {
  //   authorization: "Basic dXNlcjpwYXNzd29yZA==",
  // },
});

client.interceptors.request.use(
  (config) => {
    const auth = JSON.parse(localStorage?.getItem('auth') || "{}");
    const header = `Basic ${btoa(`${auth?.name}:${auth?.password}`)}`;

    if (header) {
      config.headers.Authorization = header;
    } else {
      console.warn("No credentials found!!!");
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const rabbitMQClient = axios.create({
  baseURL: process.env.REACT_APP_RABBITMQURL,
  headers: {
    authorization: "Basic dXNlcjpwYXNzd29yZA==",
  },
});

export const request = async ({ ...options }, notify = true) => {
  const onSuccess = (response) => {
    if (notify) {
      if (response.status === 200) {
        if (options.method === "delete") {
          ToastComp({
            varient: "success",
            message: response.message
              ? response.message
              : "Removed Successfully",
          });
        } else if (options.method === "put") {
          if (notify) {
            ToastComp({
              varient: "success",
              message: response.message
                ? response.message
                : "Updated Successfully",
            });
          }
        } else if (options.method === "post") {
          if (notify) {
            ToastComp({
              varient: "info",
              message: response.message ? response.message : "Already Added",
            });
            return response;
          } else {
            ToastComp({
              varient: "info",
              message: response.message ? response.message : "Already Added",
            });
          }
          return response;
        } else if (options.method === "patch") {
          if (notify) {
            ToastComp({
              varient: "success",
              message: response.message
                ? response.message
                : "Updated Successfully",
            });
          }
        }
        return response;
      } else if (response.status === 201) {
        if (notify == true) {
          ToastComp({
            varient: "success",
            message: response.message ? response.message : "Added Successfully",
          });
          return response;
        } else {
          return response;
        }
      } else {
        ToastComp({
          varient: "error",
          message: response.message ? response.message : "Error",
        });
        return response;
      }
    } else {
      return response;
    }
  };

  const onError = (error) => {
    console.log(
      "Error In Axios interceptor : ",
      error,
      error?.response?.data?.message
    );
    if (notify) {
      ToastComp({
        varient: "error",
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Error ! Try Again Later",
      });
      return error;
    }
    return error;
  };

  return client(options).then(onSuccess).catch(onError);
};

export const requestMq = async ({ ...options }, notify = true) => {
  const onSuccess = (response) => {
    return response;
  };

  const onError = (error) => {
    console.log(
      "Error In Axios interceptor : ",
      error,
      error?.response?.data?.message
    );
    if (notify) {
      ToastComp({
        varient: "error",
        message:
          error?.response?.data?.message ||
          error?.message ||
          "Error ! Try Again Later",
      });
      return error;
    }
    return error;
  };

  return rabbitMQClient(options).then(onSuccess).catch(onError);
};